/**
 * 批量上传
 * @param params
 * @returns {AxiosPromise}
 */
import http from "../http"
//发起调用ocr
export const runOcr =(params)=>{
    return http.getRequest(`/app/car/upload/runOcr/${params.enterpriseId}`,params)
}

export const uploadCarImag =(params, companyId)=>{
    return http.postRequest(`/ad/app/car/upload/uploadCarImag?enterpriseId=${companyId}`,params)
}

//获取本轮批量识别结果
export const  getExceByVersion = (params) => {
    return http.postRequest(`/app/car/upload/getExceByVersion`,params)
}

//结束本轮批量识别
export const  delAllExceLCarInfo = (params) => {
    return http.postRequest(`/app/car/upload/delAllExceLCarInfo`,params)
}

//保存本轮批量识别结果
export const  submitAllExceL = (params) => {
    return http.postRequest(`/ad/app/car/upload/car/submit`,params)
}


//更新批量识别单条车辆数据
export const  editExceLCarInfo = (params) => {
    return http.postRequest(`/app/car/upload/editExceLCarInfo`,params)
}

//更新批量识别单条车辆数据设为正常
export const  updateSuccess = (params) => {
    return http.postUrlParams(`/ad/app/car/upload/updateSuccess`,params)
}

//获取批量识别按钮状态
export const  getBtnFlag = (params) => {
    return http.postUrlParams(`/app/car/upload/getBtnFlag`,params)
}

//获取批量识别按钮状态
export const  delExceLCarInfo = (params) => {
    return http.postUrlParams(`/app/car/upload/delExceLCarInfo`,params)
}