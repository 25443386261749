<template>
  <div class="handle-container">
    <div class="bread">功能管理 / <span class="gray">批量上传</span></div>
    <el-row class="table-body">
      <el-row :gutter="20" class="line">
        <el-col :xs="8" :sm="6" :md="6" :lg="3" :xl="2">
          <h5 class="title">批量上传</h5>
        </el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="15" :xl="15" class="actions">
          <el-col :span="4" class="is_info ">
            <el-button type="primary" size="small"
                       @click="handleDownloadExcel"
            >下载示例<i class="el-icon-download el-icon&#45;&#45;right icon-size"
            ></i></el-button>
            <el-tooltip class="item" effect="light" content="Bottom Left 提示文字" placement="bottom-start"
                        style="display: none">
              <img src="../../img/tips.png" class="tip-button">
            </el-tooltip>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="4" :xl="4" class="is_info ">
            <el-button type="primary" size="small"
                       @click="handleToVideo"
            >视频教程<i class="el-icon-video-camera el-icon&#45;&#45;right icon-size"
            ></i></el-button>
            <el-tooltip class="item" effect="light" content="Bottom Left 提示文字" placement="bottom-start"
                        style="display: none">
              <img src="../../img/tips.png" class="tip-button">
            </el-tooltip>
          </el-col>
          <el-col :xs="12" :sm="12" :md="10" :lg="5" :xl="5" class="is_info ">
            <!--            <el-button type="primary" size="small" >上传Excel文件<i class="el-icon-upload2 el-icon&#45;&#45;right icon-size" ></i></el-button>-->
            <!--            <el-tooltip class="item" effect="light"  placement="bottom-start" popper-class="atooltip">-->
            <!--              <div slot="content" class="red">*上传Excel文件时请确认要导入的车辆品牌型号已添加在企业品牌型号库中<br/>*请确保车牌号不重复</div>-->
            <!--              <img src="../../img/tips.png" class="tip-button">-->
            <!--            </el-tooltip>-->
            <el-upload
                class="upload-demo"
                accept=".xls,.xlsx"
                :action="baseUrl + '/ad/app/car/upload/uploadExcel?enterpriseId=' + companyId"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :on-success="handleUploadExcelSuccess"
                :disabled="btnStatus.excelBtnStatus"
            >
              <el-button type="primary" size="small">上传Excel文件<i class="el-icon-upload2 el-icon--right icon-size"></i>
              </el-button>
              <el-tooltip class="item" effect="light" placement="bottom-start" popper-class="atooltip">
                <div slot="content" class="red">*上传Excel文件时请确认要导入的车辆品牌型号已添加在企业品牌型号库中<br/>*请确保车牌号不重复</div>
                <img src="../../img/tips.png" class="tip-button">
              </el-tooltip>
            </el-upload>
          </el-col>
          <el-col :xs="12" :sm="12" :md="10" :lg="5" :xl="5" class="is_info ">
            <el-button type="primary" size="small"
                       @click="toBatchImgUpload"
                       :disabled="btnStatus.imgBtnStatus"
            >批量上传图片<i class="el-icon-picture-outline el-icon--right icon-size"></i></el-button>
            <el-tooltip class="item" effect="light" placement="bottom-start" popper-class="atooltip">
              <div slot="content" class="red">*批量上传车辆图片<br/>*请确保图片正确</div>
              <img src="../../img/tips.png" class="tip-button">
            </el-tooltip>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="4" :xl="4" class="is_info ">
            <el-button type="primary" size="small"
                       @click="getExceByVersion"
                       :disabled="btnStatus.refreshBtnSatus"
            >刷新进度<i class="el-icon-refresh el-icon--right icon-size"></i></el-button>
            <el-tooltip class="item" effect="light" placement="bottom-start" popper-class="atooltip">
              <div slot="content" class="red">*上传Excel文件时请确认要导入的车辆品牌型号已添加在企业品牌型号库中<br/>*请确保车牌号不重复</div>
              <img src="../../img/tips.png" class="tip-button">
            </el-tooltip>
          </el-col>
        </el-col>
        <el-col :xs="12" :sm="12" :md="10" :lg="6" :xl="4" style="text-align: right">
          <el-button type="primary" size="small"
          @click="submitAllExceL"
          >保存数据</el-button>
          <el-button type="primary" size="small" plain @click="delAllExceLCarInfo">结束批次</el-button>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="table-body">
      <!--      <div class="line clearfix">-->
      <!--        <h5 class="fl title">车辆库</h5>-->
      <!--        <div class="fr search">-->
      <!--          <el-input size="small" type="text" placeholder="请输入关键字" v-model="keywords">-->
      <!--            <template slot="prefix">-->
      <!--              <el-button icon="el-icon-search" type="text" disabled></el-button>-->
      <!--            </template>-->

      <!--          </el-input>-->
      <!--          <el-button type="primary" size="small" @click="getList">查询</el-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="list">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="remark"
              label="备注"
          >
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.remark }}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="carLicenseFrontName"
              label="行驶证正面"
          >
            <template slot-scope="scope">
              <el-image :src="scope.row.carLicenseFrontName"></el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="carLicenseReverseSideName"
              label="行驶证反面"
          >
            <template slot-scope="scope">
              <el-image :src="scope.row.carLicenseReverseSideName"></el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="carBrand"
              label="车辆图片"
          >
            <template slot-scope="scope">
              <el-image :src="scope.row.car45Url"></el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="carNum"
              label="车牌号"
          >
          </el-table-column>
          <!---->
          <el-table-column
              prop="carBrand"
              label="品牌型号"
          >
            <template slot-scope="scope">
              {{ scope.row.carBrand }}{{ scope.row.carMode }}
            </template>
          </el-table-column>

          <el-table-column
              prop="leaseCarType"
              label="车辆类型"
          >
          </el-table-column>

          <el-table-column
              fixed="right"
              label="操作"
              >
            <template slot-scope="scope">
              <el-button
                  type="text"
                  size="small"
                  @click="handleUpdateBatchCar(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  @click="handleSetOk(scope.row)"
              >
                设为正常
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  @click="delExceLCarInfo(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <!--        <el-pagination-->
        <!--            background-->
        <!--            layout="prev, pager, next"-->
        <!--            @current-change="handleCurrentChange"-->
        <!--            :hide-on-single-page="showPage"-->
        <!--            :page-size="pageSize"-->
        <!--            :total="total">-->
        <!--        </el-pagination>-->
      </div>
    </el-row>
    <!--    <upload-imgs ref="imgs"></upload-imgs>-->
    <multeFileUpload ref="batchImgUpload" @getExceByVersion="getExceByVersion"></multeFileUpload>
    <EditBatchCar ref="editCar" @getExceByVersion="getExceByVersion"></EditBatchCar>
  </div>
</template>

<script>
import multeFileUpload from "./components/multeFileUpload";
import EditBatchCar from "@/components/EditBatchCar";
import config from '@/utils/config'
import {getStore} from "@/utils/storage";
import {getExceByVersion, delAllExceLCarInfo, updateSuccess, getBtnFlag, submitAllExceL, delExceLCarInfo} from '@/utils/api/batch'

export default {
  name: "batch",
  components: {
    // UploadImgs,
    multeFileUpload,
    EditBatchCar
  },
  data() {
    return {
      tipText: "*上传Excel文件时请确认要导入的车辆品牌型号已添加在企业品牌型号库中\n" +
          "*请确保车牌号不重复",
      baseUrl: config.baseUrl,
      staticBaseUrl: getStore('imgBase'),
      companyId: getStore("companyId"),
      headers: {
        // :
        authorization: 'Bearer ' + getStore('access_token')
      },
      tableData: [],
      btnStatus: {
        excelBtnStatus: false,
        imgBtnStatus: false,
        refreshBtnSatus: true,
        saveBtnStatus: true,
        // imgBtn: true,   //批量上传图片
        // loadBtn: true,  //当前进度：
        // loadOverBtn: true,   //上传完成
        // refBtn: true,       //刷新进度
        // submitBtn: true, //保存数据
        // upBtn: false,     //上传excel文件
      }
    }
  },
  methods: {
    uploadImgs() {

    },
    //下载excel模板
    handleDownloadExcel() {
      var src = 'https://bc-1306761403.cos.ap-shanghai.myqcloud.com/lxzl/video/cheliangdaorumoban.xlsx';
      // window.open(src)
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = "javascript: '<script>location.href=\"" + src + "\"<\/script>'";
      document.getElementsByTagName('body')[0].appendChild(iframe);
    },
    //跳转教学视频
    handleToVideo() {
      window.open("https://static.lixingpt.com/lxzl/video/%E6%93%8D%E4%BD%9C%E8%A7%86%E9%A2%91.mp4")
    },
    beforeAvatarUpload(file) {
      const isxls = file.type === 'xls,';
      const isxlsx = file.type === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 20;
      if ((isxls && isLt2M) || (isxlsx && isLt2M)) {
        return isLt2M;
      } else {
        this.$message.error('只能上传excel文件并小于20M');
        return false
      }
    },
    //excel上传成功回调
    handleUploadExcelSuccess(value) {
      if (value.code === 0) {
        this.tableData = value.data
        this.getBtnFlag()
        // this.btnStatus.excelBtnStatus = true
        // this.btnStatus.imgBtnStatus = false
      }
    },
    //上传图片
    toBatchImgUpload() {
      this.$refs['batchImgUpload'].showVisible()
    },
    //获取版本信息
    getExceByVersion() {
      this.btnStatus.refreshBtnSatus = true
      getExceByVersion().then(res => {
        if (res.code === 0) {
          // this.tableData =
          this.tableData = []
          res.data.list.map(item => {
            item.car45Url = (this.baseUrl.replace("/webapp", "")) + item.car45Url
            item.carLicenseFrontName = (this.baseUrl.replace("/webapp", "")) + item.carLicenseFrontName
            item.carLicenseReverseSideName = (this.baseUrl.replace("/webapp", "")) + item.carLicenseReverseSideName
            this.tableData.push(item)
          })
          this.getBtnFlag()
        }
        setTimeout(() => {
          this.btnStatus.refreshBtnSatus = false
        }, 10000)
      })
    },
    //结束批量
    // 点击确认会结束当前批量操作，可重新上传excel进行批量上传。
    delAllExceLCarInfo() {
      this.$confirm('点击确认会结束当前批量操作，可重新上传excel进行批量上传。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAllExceLCarInfo().then(res => {
          if (res.code === 0) {
            this.$message.success("结束成功")
            setTimeout(()=>{
              this.getExceByVersion()
            }, 3000)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    //编辑
    handleUpdateBatchCar(row){
      this.$refs['editCar'].showEdit(row)
    },
    //获取按钮状态
    getBtnFlag(){
      getBtnFlag().then(res => {
        if(res.code ===0 ){
            this.btnStatus.excelBtnStatus = res.data.btn.upBtn
            this.btnStatus.refreshBtnSatus = res.data.btn.refBtn
            this.btnStatus.imgBtnStatus = res.data.btn.imgBtn
            this.btnStatus.saveBtnStatus = res.data.btn.submitBtn
        }
      })
    },
    //设为正常
    handleSetOk(row){
      let data = {
        id: row.id
      }
      updateSuccess(data).then(res => {
        if(res.code ===0){
          this.getExceByVersion()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //保存数据
    submitAllExceL(){
      submitAllExceL().then(res => {
        if(res.code ===0){
          this.$message.success(res.data)
          this.getExceByVersion()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //删除单条数据
    delExceLCarInfo(row){
      let data = {
        id: row.id
      }
      delExceLCarInfo(data).then(res => {
        if(res.code ===0){
          this.$message.success("删除成功")
          this.getExceByVersion()
        }else{
          this.$message.error(res.data)
        }
      })
    }
  },
  created() {
    this.getExceByVersion()
    this.getBtnFlag()
  }
}
</script>

<style  lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;

  .line {
    height: 90px;
    line-height: 90px;

    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }

    .actions {
      .is_info {
        position: relative;

      }

      .red {
        border: 1px solid #FF5758;
      }
    }

    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input {
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }

        .el-input__prefix {
          width: 40px;

          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}

</style>
