<template>
  <el-dialog title="批量上传图片上传" :visible.sync="dialogVisible">
    <div>
      <el-upload
          class="upload-demo"
          :action="baseUrl + '/ad/app/car/upload/uploadCarImag?enterpriseId='+companyId"
          multiple="multiple"
          :file-list="fileList"
          :headers="headers"
          :on-success="handleStartOcr"
          :on-change="handleFileChange"
          :auto-upload="false"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
<!--      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      <el-button type="primary" @click="uploadFj">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import config from "@/utils/config";
import {getStore} from "@/utils/storage";
import {runOcr, uploadCarImag} from "@/utils/api/batch";
import axios from "axios";

export default {
  name: "multeFileUpload",
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      baseUrl: config.baseUrl,
      staticBaseUrl: getStore('imgBase'),
      companyId: getStore("companyId"),
      headers: {
        // :
        authorization: 'Bearer ' + getStore('access_token')
      },
    }
  },
  methods: {
    showVisible() {
      this.dialogVisible = true
    },
    handleFileChange(val){
      this.fileList.push(val)
    },
    handleStartOcr() {
      let data = {
        enterpriseId: this.companyId
      }
      runOcr(data).then(res => {
      })
    },
    //上传接口，进行上传
    uploadFj() {

      let formData = new FormData();
      formData.append("enterpriseId", this.companyId)
      //所需参数
      this.fileList.forEach((item) => {
        formData.append("file", item.raw);
      });
      //接口
      let that = this;
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + getStore("access_token")
        }
      }; //添加请求头
      // axios
      //     .post(
      //         `${that.baseUrl}/ad/app/car/upload/uploadCarImag?enterpriseId=${that.companyId}`,
      //         formData,
      //         {
      //           headers: that.headers
      //         }
      //     )
      //     .then((res) => {
      //       console.log(res);
      //       if (res.status === 200) {
      //         that.dialogVisible = false
      //         that.$emit('getExceByVersion')
      //       }
      //     })
      //     .catch((res) => {
      //       // this.$message({
      //       //   type: "error",
      //       //   message: "上传失败!",
      //       // });
      //     });
      that.$axios
          .post(that.baseUrl + "/ad/app/car/upload/uploadCarImag?enterpriseId="+that.companyId, formData, config)
          .then(res => {
            if (res.status === 200) {
              that.dialogVisible = false
              that.$emit('getExceByVersion')
            }
          })
          .catch(() => {
            // f.onError();
          });
    },
  }
}
</script>

<style scoped>

</style>
